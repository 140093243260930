import React from 'react';
import {
  Added,
  Improved,
  Link,
  Paragraph,
  PageWithSubNav,
  PlatformHeader,
  Fixed,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="July 2023" subnav="release-notes">
      <div id="July2023" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          {/* eslint-disable-next-line max-len */}
          <q cite="https://www.quotespedia.org/authors/p/peter-senge/all-great-things-have-small-beginnings-peter-senge/">
            All great things have small beginnings.
          </q>{' '}
          <br />
          <span className="uni-margin--half--left uni-text--small uni-color--subtle">
            - Peter Senge
          </span>
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Web"
              packageName="@hudl/uniform-web"
              packageLink="https://github.com/hudl/hudl-frontends/tree/main/packages/uniform-web/README.md"
              changelogUrl="https://github.com/hudl/hudl-frontends/tree/main/packages/uniform-web/CHANGELOG.md#580"
              storybookUrl="https://uniform-web.storybook.hudltools.com"
              versionedStorybookUrl="https://63dd878b6e1bac956cef23ec-xuwjwxatqu.chromatic.com"
              version="5.8.0"
            />
            <div className={styles.changesList}>
              <Added>
                <abbr title="Server-Side Rendering">SSR</abbr>
                -ready components are now available by importing from{' '}
                <code>@hudl/uniform-web/server</code>. See{' '}
                <Link
                  href="https://uniform-web.storybook.hudltools.com/?path=/story/ssr-server-components--page"
                  isExternal>
                  Storybook
                </Link>{' '}
                for more.
              </Added>
              <Added>
                New <code>environment</code> prop to{' '}
                <Link
                  // eslint-disable-next-line max-len
                  href="https://uniform-web.storybook.hudltools.com/?path=/docs/components-theme-themeprovider-examples--basic"
                  isExternal>
                  ThemeProvider
                </Link>
                .
              </Added>
              <Added>
                New icons: <code>4G0</code>, <code>4G25</code>,{' '}
                <code>4G50</code>, <code>4G75</code>, <code>4G100</code>,{' '}
                <code>Calendar</code>, <code>UI Auto-Scroll</code>, and{' '}
                <code>UI View List</code>.
              </Added>
              <Improved>
                Refactored <code>usePrimarySecondaryColorTheme</code> to{' '}
                <code>useTeamColorTheme</code>.
              </Improved>
              <Improved>
                The Field Hockey icon by flipping it to better represent how a
                real field hockey stick is used.
              </Improved>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              packageName="@hudl/rn-uniform"
              packageLink="https://github.com/hudl/rn-uniform/tree/main/packages/rn-uniform/README.md"
              changelogUrl="https://github.com/hudl/rn-uniform/tree/main/packages/rn-uniform/CHANGELOG.md#4110"
              version="4.11.0"
            />
            <div className={styles.changesList}>
              <Added>
                New icons: <code>Calendar</code>, <code>Subtract</code>,{' '}
                <code>UI Auto-Scroll</code>, and <code>UI View List</code>.
              </Added>
              <Improved>
                The Field Hockey icon by flipping it to better represent how a
                real field hockey stick is used.
              </Improved>
              <Fixed>
                Missing <code>isEmpty</code> styles in the{' '}
                <Link href="/components/modules/data-card/design">
                  Data Card
                </Link>
                .
              </Fixed>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
